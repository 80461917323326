<!--<div loading-status-message style="position:fixed; display:none;z-index:99999;left: 50%;margin-left: -100px;top:200px;">
      <style>
        #app_loader {
            border: 16px solid #f3f3f3;
            border-radius: 50%;
            border-top: 16px solid #ed2937;
            width: 120px;
            height: 120px;
            -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
            z-index: 999;
        }

        @-webkit-keyframes spin {
            0% { -webkit-transform: rotate(0deg); }
            100% { -webkit-transform: rotate(360deg); }
        }

        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }

        /**https://stackoverflow.com/questions/34762009/pulsing-heart-css-animation**/
        @keyframes heartbeat {
            0% {
                transform: scale( .75);
            }
            20% {
                transform: scale( 1);
            }
            40% {
                transform: scale( .75);
            }
            60% {
                transform: scale( 1);
            }
            80% {
                transform: scale( .75);
            }
            100% {
                transform: scale( .75);
            }
        }

        #heart {
            z-index: 9999;
            position: relative;
            width: 50px;
            height:45px;
            animation: heartbeat 1s infinite;
            /**-webkit-filter: drop-shadow(0 2px 4px rgba(0,0,0,.5));
            -moz-filter: drop-shadow(0 2px 4px rgba(0,0,0,.5));
            -ms-filter: drop-shadow(0 2px 4px rgba(0,0,0,.5));
            -o-filter: drop-shadow(0 2px 4px rgba(0,0,0,.5));
            filter: drop-shadow(0 2px 4px rgba(0,0,0,.5));**/

            -webkit-filter: drop-shadow(0 2px 2px rgba(0,0,0,.5));
            -moz-filter: drop-shadow(0 1px 2px rgba(0,0,0,.5));
            -ms-filter: drop-shadow(0 1px 2px rgba(0,0,0,.5));
            -o-filter: drop-shadow(0 1px 2px rgba(0,0,0,.5));
            filter: drop-shadow(0 1px 2px rgba(0,0,0,.5));
        }
        #heart:before,
        #heart:after {
            position: absolute;
            content: "";
            left: 25px;
            top: 0;
            width: 25px;
            height: 40px;
            background: red;
            -moz-border-radius: 25px 25px 0 0;
            border-radius: 25px 25px 0 0;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
            -webkit-transform-origin: 0 100%;
            -moz-transform-origin: 0 100%;
            -ms-transform-origin: 0 100%;
            -o-transform-origin: 0 100%;
            transform-origin: 0 100%;
        }
        #heart:after {
            left: 0;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            -webkit-transform-origin: 100% 100%;
            -moz-transform-origin: 100% 100%;
            -ms-transform-origin: 100% 100%;
            -o-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
        }

        #loading_overlay {
            opacity:0.7;
            filter: alpha(opacity=20);
            background-color:#fff;
            width:100%;
            height:100%;
            z-index:10;
            top:0;
            left:0;
            position:fixed;
        }

    </style>


    <div id="app_loader"></div>
    <div id="loading_overlay"></div>

</div>--->

  <!-- content -->
  <div class="app-content">
      <div ui-butterbar></div>
      <a href class="off-screen-toggle hide" ui-toggle-class="off-screen" data-target=".app-aside" ></a>
      <div class="app-content-body fade-in-up" ui-view></div>

      <div class="wrapper-xl">
          <div class="row">
              <div class="col-sm-12 padder-lg">
                  <div class="line b-b b-black line-lg"></div>
                  © 2025, Cardiovascular Research Foundation<sup>®</sup>. All rights reserved. | <a style="text-decoration: underline" href="https://www.crf.org/privacy-policy" target="_blank">Privacy Policy</a>
              </div>
          </div>
      </div>
  </div>
  <!-- /content -->