<div data-ng-include="'blocks/conference_logo.html'"></div>

<div class="wrapper-lg">
    <div class="padder-lg">

        <div data-ng-include="'blocks/conference_nav.html'"></div>

        <p class="font-bold">
            So we may optimize your experience at {{conference.title}}, please fill out all of the following sections. Required items are markeded with an asterisk(*). You must click the Add button on the right side of the page after each entry in all sections below.
            Click the Save <span ng-show="task.status == '0'">and Continue</span> button at the bottom of this page when you are finished.
        </p>

        <!-- AREAS OF EXPERTISE -->
        <div class="m-t-lg">
            <div class="hbox">
                <div class="col">
                    <span class="h4 font-bold">AREAS OF EXPERTISE</span> <span><code>*</code></span></a>
                </div>
            </div>

            <div class="line line-double b-b b-t b-primary2"></div>
            <div ng-include="'partials/areas_of_expertise.html'"></div>
        </div>

        <div class="m-t-xl text-danger" ng-show="error_msg">
            <span class="font-bold">Please fill-out the following required fields:</span>
            <div class="line line-dashed b-b b-danger"></div>
            <span class="font-bold" ng-bind-html="error_msg | unsafe"></span>
            <div class="line line-dashed b-b b-danger"></div>
        </div>

        <div class="m-t-lg text-center">
            <button ng-click="saveTask()" type="button" class="btn btn-success">Save <span ng-show="task.status == '0'">and Continue</span></button>
            <button ng-click="cancelTask()" type="button" class="btn btn-danger m-l-sm ">Cancel</button>
        </div>

    </div>
</div>