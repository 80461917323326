<div class="wrapper-xl">
    <div class="row">
        <div class="col-sm-12 padder-lg">
            <h1 class="font-thin h3">Conferences</h1>
            <div class="b-5x b-b b-black m-t-md m-b"></div>

            <div ng-repeat="conference in conferences">
            <h4 class="font-thin">
<!--

--->
                    <a ui-sref="app.conference_tasks({'conference_id' : conference.id})" class="text-info">{{conference.title}}</a>
                </h4>
                <span ng-show="conference.datetime_start == conference.datetime_end">
                    {{formatMomentDate(conference.datetime_start, 'MMM DD') }} |
                </span>
                <span ng-show="conference.datetime_start != conference.datetime_end">
                    {{formatMomentDate(conference.datetime_start, 'MMM DD')}} - {{formatMomentDate(conference.datetime_end, 'MMM DD') }} |
                </span>
                {{conference.venue.name}}
                <br/>
                <span ng-switch="conference.faculty.availability">
                    <em ng-switch-when="Yes">
                        <i uib-tooltip="Accepted" class="fa fa-fw fa-check-circle text-success"></i> Accepted invitation
                    </em>
                    <em ng-switch-when="No">
                        <i uib-tooltip="Declined" class="fa fa-fw fa-times-circle text-danger"></i> Declined invitation
                    </em>
                    <em ng-switch-when="Invited">
                        <i uib-tooltip="Unknown" class="fa fa-fw fa-question-circle text-warning text-warning-print"></i> Awaiting response
                    </em>
                </span>
                <div class="line line-dashed line-lg b-b b-black" ng-show="!$last"></div>
            </div>

        </div>
    </div>
</div>
