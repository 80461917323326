<p class="h4 text-center m-b-md">
    I agree to participate as faculty at {{conference.title}} and acknowledge my acceptance of the CRF®
    <a style="text-decoration: underline" href="https://www.crf.org/privacy-policy" target="_blank">Privacy Policy</a>,
    <a style="text-decoration: underline" href="https://www.crf.org/code-of-conduct" target="_blank">Code of Conduct</a>,
    and <a style="text-decoration: underline" href="https://www.crf.org/terms-and-conditions" target="_blank">Terms and Conditions</a>.

</p>

<div class="text-center m-b-lg">
    <div class="btn-group m-t-xs"
         ng-init="vm.form[form_config.type].field_model[field.name] = (field.value != null) ? field.value : field.default"
    >
        <label ng-class="{ 'btn-default' : availability != 'Yes', 'btn-success active' : availability == 'Yes' }"
               class="btn btn-sm btn-default"
               ng-model="$parent.$parent.availability"
               uib-btn-radio="'Yes'"
               uncheckable
        ><i class="fa fa-check text-active"></i> <span>Yes</span></label>

        <label ng-class="{ 'btn-default' : availability != 'No', 'btn-danger active' : availability == 'No' }"
               class="btn btn-sm btn-default"
               ng-model="$parent.$parent.availability"
               uib-btn-radio="'No'"
               uncheckable
        ><i class="fa fa-check text-active"></i> <span>No</span></label>

    </div>
</div>

<div class="line  line-double line-lg b-b b-t b-primary2" ng-show="availability == 'No'"></div>

<div>
    <ul>
        <li ng-repeat="note in conference.faculty.notes"
            ng-show="note.note_title == 'Availability Comment'">{{note.note}}<br>
            <em class="small">Entered on {{formatMomentDate(note.updated_at, 'MM/DD/YYYY')}}</em></li>
    </ul>
    <div ng-show="availability == 'No'">
        <em><code class="text-danger">*</code> Reason for Decline: </em>
        <textarea ng-model="availability_comments.text" style="width: 100%; height: 50px;"></textarea>
    </div>
</div>


