<div class="wrapper-xl">
    <div class="row">
        <div class="col-sm-12 padder-lg">
            <h1 class="font-thin h3">Areas Of Expertise</h1>
            <div class="b-5x b-b b-black m-t-md m-b"></div>

            <!-- AREAS OF EXPERTISE -->
            <div class="m-t-lg">
                <div ng-include="'partials/areas_of_expertise.html'"></div>
            </div>

            <div class="m-t-xl text-danger" ng-show="error_msg">
                <span class="font-bold">Please fill-out the following required fields:</span>
                <div class="line line-dashed b-b b-danger"></div>
                <span class="font-bold" ng-bind-html="error_msg | unsafe"></span>
                <div class="line line-dashed b-b b-danger"></div>
            </div>

            <div class="m-t-lg text-center">
                <button ng-click="save()" type="button" class="btn btn-success">Save</button>
                <button ng-click="cancel()" type="button" class="btn btn-danger m-l-sm ">Cancel</button>
            </div>

        </div>
    </div>
</div>
