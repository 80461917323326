<div ng-controller="FormController as vm" ng-class="{ 'disabled-overlay' : $root.disable_submit}">

    <script type="text/ng-template" id="field.datetime">
        <div ng-switch="field.params.readonly" ng-if="vm.form[form_config.type].field_show[field.name]">
            <div ng-switch-when="true">
                <div class="form-group">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <div>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                        </div>
                    </label>

                    <div class="col-xs-6 col-sm-8">
                        <span ng-hide="field.params.readonly_msg">{{vm.form[form_config.type].field_model[field.name]}}</span>
                        <span ng-show="field.params.readonly_msg"><em>{{field.params.readonly_msg}}</em></span>
                    </div>
                </div>
            </div>
            <div ng-switch-default>
                <div id="{{field.name}}" class="form-group" ng-class="{ 'has-error' : vm.appform[form_config.type][field.name].$invalid && !vm.appform[form_config.type][field.name].$pristine }">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <p>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                            <span ng-show="field.rules.required" class="m-l-xs"><code>*</code></span>
                        </p>
                        <em><span class="text-muted" ng-bind-html="field.description | unsafe"></span></em>
                    </label>

                    <div class="col-xs-6 col-sm-8">

                        <div class="input-group">

                            <input class="form-control"
                                   datetimepicker
                                   name="{{field.name}}"
                                   ng-model="vm.form[form_config.type].field_model[field.name]"
                                   options="vm.form[form_config.type].field_settings[field.name].datepicker_options"
                                   ng-required="field.rules.required"
                                   readonly="readonly"
                            />
                            <span class="input-group-addon">
                                <span class="glyphicon glyphicon-calendar"></span>
                            </span>
                        </div>

                        <!--<div ng-messages="vm.appform[form_config.type][field.name].$error">
                            <div class="pull-left" ng-repeat="rule in field.rules">
                                <div ng-message-exp="rule.rule_type" class="help-block"><i>{{rule.rule_msg}}</i></div>
                            </div>
                        </div>-->

                    </div>
                </div>
            </div>
        </div>


    </script>

    <script type="text/ng-template" id="field.radio2">
        <div ng-switch="field.params.readonly" ng-if="vm.form[form_config.type].field_show[field.name]">
            <div ng-switch-when="true">

                <div>
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <div>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                        </div>
                    </label>

                    <div class="col-xs-6 col-sm-8">
                        <div ng-hide="field.params.readonly_msg" ng-repeat="option in field.options">
                            <span ng-if="field.value == option.option_value">{{option.option_text}}</span>
                        </div>

                        <span ng-show="field.params.readonly_msg"><em>{{field.params.readonly_msg}}</em></span>
                    </div>
                </div>

            </div>
            <div ng-switch-default>

                <div id="{{field.name}}" class="form-group" ng-class="{ 'has-error' : vm.appform[form_config.type][field.name].$invalid && !vm.appform[form_config.type][field.name].$pristine }">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <p>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                            <span ng-show="field.rules.required" class="m-l-xs"><code>*</code></span>
                        </p>
                        <em><span class="text-muted" ng-bind-html="field.description | unsafe"></span></em>
                    </label>

                    <div class="col-xs-6 col-sm-8">

                        <div class="radio" ng-repeat="option in field.options" ng-class="{'pull-left m-l-sm' : vm.form[form_config.type].data.sub_type == 'search'}">

                            <label>
                                <input type="radio"
                                       name="{{field.name}}"
                                       ng-init="vm.form[form_config.type].field_model[field.name] = (field.value != null) ? field.value : field.default"
                                       ng-model="vm.form[form_config.type].field_model[field.name]"
                                       value="{{option.option_value}}"
                                       ng-required="field.rules.required"
                                       ng-click="vm.resetRadioField($event, field)"
                                > <span ng-bind-html="option.option_text | unsafe"></span>
                            </label>
                        </div>

                        <div ng-messages="vm.appform[form_config.type][field.name].$error">
                            <div class="pull-left" ng-repeat="rule in field.rules">
                                <div ng-message-exp="rule.rule_type" class="help-block"><i>{{rule.rule_msg}}</i></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </script>

    <script type="text/ng-template" id="field.radio">
        <div ng-switch="field.params.readonly" ng-if="vm.form[form_config.type].field_show[field.name]">
            <div ng-switch-when="true">

                <div>
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <div>
                            <span ng-class="{ 'text-muted' : field.params.readonly_msg }" class="font-bold" ng-bind-html="field.title | unsafe"></span>
                        </div>
                    </label>

                    <div class="col-xs-6 col-sm-8">
                        <div ng-hide="field.params.readonly_msg" ng-repeat="option in field.options">
                            <span ng-if="field.value == option.option_value">{{option.option_text}}</span>
                        </div>

                        <span ng-show="field.params.readonly_msg"><em class="text-muted">{{field.params.readonly_msg}}</em></span>
                    </div>
                </div>

            </div>
            <div ng-switch-default>

                <div id="{{field.name}}" class="form-group" ng-class="{ 'has-error' : vm.appform[form_config.type][field.name].$invalid && !vm.appform[form_config.type][field.name].$pristine }">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <p>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                            <span ng-show="field.rules.required" class="m-l-xs"><code>*</code></span>
                        </p>
                        <em><span class="text-muted" ng-bind-html="field.description | unsafe"></span></em>
                    </label>

                    <div class="col-xs-6 col-sm-8">

                        <div class="btn-group m-t-xs"
                             ng-init="vm.form[form_config.type].field_model[field.name] = (field.value != null) ? field.value : field.default"
                        >

                            <!--<button ng-repeat="option in field.options"
                                    type="button"
                                    class="btn btn-default btn-xs"
                                    ng-class="{ 'btn-default' : vm.form[form_config.type].field_model[field.name] != option.option_value, 'btn-success' : vm.form[form_config.type].field_model[field.name] == option.option_value}"
                                    ng-click="vm.resetButtonGroupField(option.option_value, field)"
                            >{{option.option_text}}</button>--->

                            <label ng-repeat="option in field.options"
                                   ng-class="{ 'btn-default' : vm.form[form_config.type].field_model[field.name] != option.option_value, 'btn-success active' : vm.form[form_config.type].field_model[field.name] == option.option_value && option.option_text != 'No', 'btn-danger active' : vm.form[form_config.type].field_model[field.name] == option.option_value && option.option_text == 'No' }"
                                   class="btn btn-xs btn-default"
                                   ng-model="vm.form[form_config.type].field_model[field.name]"
                                   uib-btn-radio="'{{option.option_value}}'"
                                   uncheckable
                            ><i class="fa fa-check text-active" ng-show="vm.form[form_config.type].field_model[field.name] == option.option_value"></i> <span ng-bind-html="option.option_text | unsafe"></span></label>

                            <!-- TODO: add required support -->
                            <input name="{{field.name}}" ng-model="vm.form[form_config.type].field_model[field.name]" type="hidden" ng-required="field.rules.required">
                        </div>

                        <div ng-messages="vm.appform[form_config.type][field.name].$error">
                            <div class="pull-left" ng-repeat="rule in field.rules">
                                <div ng-message-exp="rule.rule_type" class="help-block"><i>{{rule.rule_msg}}</i></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </script>

    <script type="text/ng-template" id="field.switch">
        <div ng-switch="field.params.readonly" ng-if="vm.form[form_config.type].field_show[field.name]">
            <div ng-switch-when="true">

                <div>
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <div>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                        </div>
                    </label>

                    <div class="col-xs-6 col-sm-8">
                        <div ng-hide="field.params.readonly_msg" ng-repeat="option in field.options">
                            <span ng-if="field.value == option.option_value">{{option.option_text}}</span>
                        </div>

                        <span ng-show="field.params.readonly_msg"><em>{{field.params.readonly_msg}}</em></span>
                    </div>
                </div>

            </div>
            <div ng-switch-default>

                <div id="{{field.name}}" class="form-group" ng-class="{ 'has-error' : vm.appform[form_config.type][field.name].$invalid && !vm.appform[form_config.type][field.name].$pristine }">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <p>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                            <span ng-show="field.rules.required" class="m-l-xs"><code>*</code></span>
                        </p>
                        <em><span class="text-muted" ng-bind-html="field.description | unsafe"></span></em>
                    </label>

                    <div class="col-xs-6 col-sm-8">

                        <switch
                                id="{{field.name}}"
                                name="{{field.name}}"
                                ng-model="vm.form[form_config.type].field_model[field.name]"
                                class="small green"
                        ></switch>

                        <div ng-messages="vm.appform[form_config.type][field.name].$error">
                            <div class="pull-left" ng-repeat="rule in field.rules">
                                <div ng-message-exp="rule.rule_type" class="help-block"><i>{{rule.rule_msg}}</i></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </script>

    <script type="text/ng-template" id="field.select">
        <div ng-switch="field.params.readonly" ng-if="vm.form[form_config.type].field_show[field.name]">

            <div ng-switch-when="true">

                <div class="form-group">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <div>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                        </div>
                    </label>

                    <div class="col-xs-6 col-sm-8" style="margin-bottom: 2px;">

                        <span ng-hide="field.params.readonly_msg" ng-switch="field.params.type">
                            <span ng-switch-when="single">
                                <span>{{vm.form[form_config.type].field_model[field.name].selected.option_text}}</span>
                            </span>
                            <span ng-switch-when="multiple">
                                <span ng-switch="field.params.grouped">
                                    <span ng-switch-when="true">
                                        <!-- Multiple Grouped -->
                                        <!-- TODO: display read-only version -->
                                        {{vm.form[form_config.type].field_model[field.name]}}
                                    </span>
                                    <span ng-switch-when="false">
                                        <!-- Multiple -->
                                        <span ng-repeat="field_value in vm.form[form_config.type].field_model[field.name].selected">
                                            {{field_value.option_text}},
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>

                        <span ng-show="field.params.readonly_msg"><em>{{field.params.readonly_msg}}</em></span>

                    </div>
                </div>

            </div>
            <div ng-switch-default>
                <div id="{{field.name}}" class="form-group"
                     ng-class="{ 'has-error' : vm.appform[form_config.type][field.name].$invalid && !vm.appform[form_config.type][field.name].$pristine }">
                    <label ng-show="!field.params.depends_on || vm.form[form_config.type].field_options[field.name].length"
                           class="col-xs-6  col-sm-4 control-label form-field-label">
                        <p>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                            <span ng-show="field.rules.required" class="m-l-xs"><code>*</code></span>
                        </p>
                        <em><span class="text-muted" ng-bind-html="field.description | unsafe"></span></em>
                    </label>

                    <div class="col-xs-6 col-sm-8" style="margin-bottom: 2px;">

                        <span ng-switch="field.params.type">
                            <span ng-switch-when="single">
                                    <span ng-switch="field.params.grouped">
                                        <span ng-switch-when="true">
                                            <!-- Single Grouped -->
                                            <ui-select ng-model="vm.form[form_config.type].field_model[field.name].selected"
                                                       theme="bootstrap"
                                                       ng-required="field.rules.required"
                                                       name="{{field.name}}"
                                            >
                                                <ui-select-match placeholder="Select or type to search ...">{{$select.selected.parent_title}} - {{$select.selected.option_text}}</ui-select-match>
                                                <ui-select-choices
                                                        group-by="'parent_title'"
                                                        repeat="item in field.options | filter: $select.search"
                                                        all-choices="field.options"
                                                        refresh-delay="0"
                                                        ui-disable-choice="item.disabled"
                                                >
                                                  <div ng-bind-html="item.option_text | highlight: $select.search"></div>
                                                </ui-select-choices>
                                            </ui-select>
                                        </span>
                                        <span ng-switch-when="false">
                                            <!-- Single Non-Grouped -->

                                            <!-- Single Non-Grouped - Non-dependent field -->
                                            <span ng-if="!field.params.depends_on && !field.params.list_url">
                                                <ui-select ng-model="vm.form[form_config.type].field_model[field.name].selected"
                                                           theme="bootstrap"
                                                           name="{{field.name}}"
                                                           ng-required="field.rules.required"
                                                >
                                                    <ui-select-match placeholder="Select or type to search ...">{{$select.selected.option_text}}</ui-select-match>
                                                    <ui-select-choices
                                                            repeat="item in field.options | filter: $select.search"
                                                            all-choices="field.options"
                                                            refresh-delay="0"
                                                            ui-disable-choice="item.disabled"
                                                    >
                                                      <div ng-bind-html="item.option_text | highlight: $select.search"></div>
                                                    </ui-select-choices>
                                                </ui-select>
                                            </span>

                                            <!-- Single Non-Grouped - Dependent field -->
                                            <span ng-if="field.params.depends_on">
                                              <span ng-if="vm.form[form_config.type].field_options[field.name].length">
                                                  <ui-select ng-model="vm.form[form_config.type].field_model[field.name].selected"
                                                             theme="bootstrap"
                                                             ng-required="field.rules.required"
                                                             name="{{field.name}}"
                                                  >
                                                      <ui-select-match placeholder="Select or type to search ...">{{$select.selected.option_text}}</ui-select-match>
                                                      <ui-select-choices
                                                              repeat="item in vm.form[form_config.type].field_options[field.name] | filter: $select.search"
                                                              all-choices="vm.form[form_config.type].field_options[field.name]"
                                                              refresh-delay="0"
                                                              ui-disable-choice="item.disabled"
                                                      >
                                                        <div ng-bind-html="item.option_text | highlight: $select.search"></div>
                                                      </ui-select-choices>
                                                  </ui-select>
                                              </span>

                                                <!-- Dependent field - show if no parent value is selected-->
                                                <!--
                                                 <span ng-show="!vm.form[form_config.type].field_options[field.name].length">
                                                    <input type="text"
                                                           class="form-control input-sm"
                                                           ng-model="vm.form[form_config.type].field_model[field.name].selected"
                                                           ng-required="field.rules.required"
                                                           name="{{field.name}}"
                                                           ng-maxlength="field.rules.maxlength.rule_value"
                                                           ng-minlength="field.rules.minlength.rule_value"
                                                           ng-pattern="patterns[field.rules.pattern.rule_value]"
                                                    />
                                                </span>
                                                -->

                                           </span>
                                        </span>
                                    </span>
                            </span>
                            <span ng-switch-when="multiple">
                                    <span ng-switch="field.params.grouped">
                                        <span ng-switch-when="true">
                                            <!-- Multiple Grouped - Non-Dependent field-->
                                            <span ng-if="!field.params.depends_on">
                                                <ui-select multiple
                                                           ng-model="vm.form[form_config.type].field_model[field.name].selected"
                                                           theme="bootstrap"
                                                           ng-required="field.rules.required"
                                                           reset-search-input
                                                           name="{{field.name}}"
                                                           ng-required="field.rules.required"
                                                >
                                                <ui-select-match placeholder="Select multiple options ...">{{$item.parent_title}} - {{$item.option_text}}</ui-select-match>
                                                <ui-select-choices
                                                        group-by="'parent_title'"
                                                        repeat="item in field.options | filter: $select.search"
                                                        all-choices="field.options"
                                                        refresh-delay="0"
                                                        ui-disable-choice="item.disabled"
                                                >
                                                  <div ng-bind-html="item.option_text | highlight: $select.search"></div>
                                                </ui-select-choices>
                                            </ui-select>
                                            </span>
                                            <!-- Multiple Grouped - Dependent field-->
                                             <span ng-if="field.params.depends_on">
                                              <span ng-show="vm.form[form_config.type].field_options[field.name].length">
                                                <ui-select multiple
                                                           ng-model="vm.form[form_config.type].field_model[field.name].selected"
                                                           theme="bootstrap"
                                                           ng-required="field.rules.required"
                                                           reset-search-input
                                                           name="{{field.name}}"
                                                           ng-required="field.rules.required"
                                                >
                                                <ui-select-match placeholder="Select multiple options ...">{{$item.parent_title}} - {{$item.option_text}}</ui-select-match>

                                                <ui-select-choices
                                                        group-by="'parent_title'"
                                                        repeat="item in vm.form[form_config.type].field_options[field.name] | filter: $select.search"
                                                        all-choices="vm.form[form_config.type].field_options[field.name]"
                                                        refresh-delay="0"
                                                        ui-disable-choice="item.disabled"
                                                >
                                                  <div ng-bind-html="item.option_text | highlight: $select.search"></div>
                                                </ui-select-choices>
                                            </ui-select>
                                              </span>
                                             </span>
                                        </span>
                                        <span ng-switch-when="false">
                                            <!-- Multiple -->
                                            <ui-select multiple
                                                       ng-model="vm.form[form_config.type].field_model[field.name].selected"
                                                       theme="bootstrap"
                                                       ng-required="field.rules.required"
                                                       reset-search-input
                                                       name="{{field.name}}"
                                            >
                                                <ui-select-match placeholder="Select multiple options ...">{{$item.option_text}}</ui-select-match>
                                                <ui-select-choices
                                                        repeat="item in field.options | filter: $select.search"
                                                        all-choices="field.options"
                                                        refresh-delay="0"
                                                        ui-disable-choice="item.disabled"
                                                >
                                                  <div ng-bind-html="item.option_text | highlight: $select.search"></div>
                                                </ui-select-choices>
                                            </ui-select>
                                        </span>
                                    </span>
                                </span>
                        </span>

                        <div class="input-group-btn" ng-show="field.params.show_clear">
                            <button type="button" ng-click="vm.form[form_config.type].field_model[field.name].selected = undefined" class="btn btn-xs btn-danger">
                                Clear
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </script>

    <script type="text/ng-template" id="field.select_search">
        <div ng-switch="field.params.readonly" ng-if="vm.form[form_config.type].field_show[field.name]">

            <div ng-switch-when="true">

                <div class="form-group">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <div>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                        </div>
                    </label>

                    <div class="col-xs-6 col-sm-8" style="margin-bottom: 2px;">
                        <span ng-hide="field.params.readonly_msg">{{field.value}}</span>
                        <span ng-show="field.params.readonly_msg"><em>{{field.params.readonly_msg}}</em></span>
                    </div>
                </div>

            </div>
            <div ng-switch-default>
                <div id="{{field.name}}" class="form-group" ng-class="{ 'has-error' : vm.appform[form_config.type][field.name].$invalid && !vm.appform[form_config.type][field.name].$pristine }">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <p>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                            <span ng-show="field.rules.required" class="m-l-xs"><code>*</code></span>
                        </p>
                        <em><span class="text-muted" ng-bind-html="field.description | unsafe"></span></em>
                    </label>

                    <div class="col-xs-6 col-sm-8">

                        <input type="{{field.type}}"
                               class="form-control input-sm"
                               ng-init="vm.form[form_config.type].field_model[field.name] = field.value"
                               ng-model="vm.form[form_config.type].field_model[field.name]"
                               ng-required="field.rules.required"
                               name="{{field.name}}"
                               ng-maxlength="field.rules.maxlength.rule_value"
                               ng-minlength="field.rules.minlength.rule_value"
                               ng-pattern="patterns[field.rules.pattern.rule_value]"
                               uib-typeahead="suggestion for suggestion in vm.searchField(field.params.list_url, field.params.search_param, $viewValue)"
                               typeahead-loading="loading_typeahead"
                               typeahead-no-results="no_results"
                               typeahead-min-length="field.params.search_character_min"
                               typeahead-editable="field.params.allow_new"
                        />

                        <i ng-show="loading_typeahead" class="glyphicon glyphicon-refresh"></i>

                        <div ng-show="no_results && !field.params.allow_new">
                            <i class="glyphicon glyphicon-remove"></i> No Results Found
                        </div>

                        <div ng-messages="vm.appform[form_config.type][field.name].$error">
                            <div class="pull-left" ng-repeat="rule in field.rules">
                                <div ng-if="rule.rule_type != 'required'" ng-message-exp="rule.rule_type" class="help-block"><i>{{rule.rule_msg}}</i></div>
                            </div>
                            <div class="pull-left">
                                <div ng-message="url" class="help-block"><i>Invalid url format. eg. http://www.example.com</i></div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </script>

    <script type="text/ng-template" id="field.text">

        <div ng-switch="field.params.readonly" ng-if="vm.form[form_config.type].field_show[field.name]">
            <div ng-switch-when="true">
                <div class="form-group">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <div>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                        </div>
                    </label>

                    <div class="col-xs-6 col-sm-8">
                        <span ng-hide="field.params.readonly_msg">{{field.value}}</span>
                        <span ng-show="field.params.readonly_msg"><em>{{field.params.readonly_msg}}</em></span>
                    </div>
                </div>
            </div>
            <div ng-switch-default>
                <div id="{{field.name}}" class="form-group" ng-class="{ 'has-error' : vm.appform[form_config.type][field.name].$invalid && !vm.appform[form_config.type][field.name].$pristine }">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <p>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                            <span ng-show="field.rules.required" class="m-l-xs"><code>*</code></span>
                        </p>
                        <em><span class="text-muted" ng-bind-html="field.description | unsafe"></span></em>
                    </label>

                    <div class="col-xs-6 col-sm-8">

                        <input type="{{field.type}}"
                               ng-model-options="{debounce: 500}"
                               class="form-control input-sm"
                               ng-init="vm.form[form_config.type].field_model[field.name] = field.value"
                               ng-model="vm.form[form_config.type].field_model[field.name]"
                               ng-required="field.rules.required"
                               name="{{field.name}}"
                               ng-maxlength="field.rules.maxlength.rule_value"
                               ng-minlength="field.rules.minlength.rule_value"
                               ng-pattern="patterns[field.rules.pattern.rule_value]"
                               autocomplete="{{field.rules.autocomplete.rule_value}}"
                               autocorrect="off"
                               spellcheck="false"
                        />

                        <div ng-messages="vm.appform[form_config.type][field.name].$error">
                            <div class="pull-left" ng-repeat="rule in field.rules">
                                <div ng-if="rule.rule_type != 'required'" ng-message-exp="rule.rule_type" class="help-block"><i>{{rule.rule_msg}}</i></div>
                            </div>
                            <div class="pull-left">
                                <div ng-message="url" class="help-block"><i>Invalid url format. eg. http://www.example.com</i></div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </script>

    <script type="text/ng-template" id="field.textarea">

        <div ng-switch="field.params.readonly" ng-if="vm.form[form_config.type].field_show[field.name]">
            <div ng-switch-when="true">
                <div class="form-group">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <div>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                        </div>
                    </label>

                    <div class="col-xs-6 col-sm-8">
                        <span ng-hide="field.params.readonly_msg">{{field.value}}</span>
                        <span ng-show="field.params.readonly_msg"><em>{{field.params.readonly_msg}}</em></span>
                    </div>
                </div>
            </div>
            <div ng-switch-default>
                <div id="{{field.name}}" class="form-group" ng-class="{ 'has-error' : vm.appform[form_config.type][field.name].$invalid && !vm.appform[form_config.type][field.name].$pristine }">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <p>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                            <span ng-show="field.rules.required" class="m-l-xs"><code>*</code></span>
                        </p>
                        <em><span class="text-muted" ng-bind-html="field.description | unsafe"></span></em>
                    </label>

                    <div class="col-xs-6 col-sm-8">

                        <textarea ng-model-options="{debounce: 500}"
                                  ng-required="field.rules.required"
                                  ng-model="vm.form[form_config.type].field_model[field.name]"
                                  ng-init="vm.form[form_config.type].field_model[field.name] = field.value"></textarea>

                        <div ng-messages="vm.appform[form_config.type][field.name].$error">
                            <div class="pull-left" ng-repeat="rule in field.rules">
                                <div ng-if="rule.rule_type != 'required'" ng-message-exp="rule.rule_type" class="help-block"><i>{{rule.rule_msg}}</i></div>
                            </div>
                            <div class="pull-left">
                                <div ng-message="url" class="help-block"><i>Invalid url format. eg. http://www.example.com</i></div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </script>

    <script type="text/ng-template" id="field.static-text">

        <div class="form-group" ng-if="vm.form[form_config.type].field_show[field.name]">
            <div class="col-sm-12">
                <span ng-hide="field.params.readonly_msg" ng-bind-html="field.default | unsafe"></span>
                <span ng-show="field.params.readonly_msg"><em>{{field.params.readonly_msg}}</em></span>
            </div>
        </div>

    </script>

    <script type="text/ng-template" id="field.static-text-field">

        <div class="form-group" ng-if="vm.form[form_config.type].field_show[field.name]">
            <label class="col-xs-6  col-sm-4 control-label form-field-label">
                <div>
                    <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                </div>
                <em><span class="text-muted" ng-bind-html="field.description | unsafe"></span></em>
            </label>

            <div class="col-xs-6 col-sm-8">
                <span>{{field.value}}</span>
                <span ng-show="!field.value">{{field.default}}</span>
            </div>
        </div>

    </script>

    <script type="text/ng-template" id="field.file_upload">
        <div ng-switch="field.params.readonly" ng-if="vm.form[form_config.type].field_show[field.name]">
            <div ng-switch-when="true">
                <div class="form-group">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <div>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                        </div>
                    </label>

                    <div class="col-xs-6 col-sm-8">
                        <span ng-show="field.params.readonly_msg"><em>{{field.params.readonly_msg}}</em></span>

                        <div ng-hide="field.params.readonly_msg" ng-switch="field.params.type">
                            <div ng-switch-when="multiple">
                                <div data-ng-include=" 'views/conferences/partials/attachments_list.html' "></div>
                            </div>
                            <div ng-switch-when="single">

                                <div ng-switch="field.params.file_type">
                                    <div ng-switch-when="image">
                                        <div class="wrapper-sm ">
                                            <a target="_blank" href="{{field.value[0].url}}"><img class="thumb-xl" ng-show="field.value[0].url" ng-src="{{field.value[0].url}}?cache={{vm.form[form_config.type].cache_timestamp}}" /></a>
                                        </div>
                                    </div>
                                    <div ng-switch-default>
                                        <a target="_blank" href="{{field.value[0].url}}"><span class="m-r-xs"><i class="fa fa-file-o"></i></span>{{field.value[0].file_name}}</a>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div ng-switch-default>
                <div id="{{field.name}}" class="form-group" ng-class="{ 'has-error' : vm.appform[form_config.type][field.name].$invalid && !vm.appform[form_config.type][field.name].$pristine }">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <p>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                            <span ng-show="field.rules.required" class="m-l-xs"><code>*</code></span>
                        </p>
                        <em><span class="text-muted" ng-bind-html="field.description | unsafe"></span></em>
                    </label>

                    <div class="col-xs-6 col-sm-8">


                        <!-- FILE LIST -->
                        <div ng-switch="field.params.type">
                            <div ng-switch-when="multiple">
                                <div class="m-b-sm" data-ng-include=" 'views/conferences/partials/campaign_attachments_list.html' "></div>
                            </div>
                            <div ng-switch-when="single">

                                <div ng-switch="field.params.file_type" ng-show="field.value[0].url">
                                    <div ng-switch-when="image">
                                        <div class="wrapper-sm">
                                            <a target="_blank" href="{{field.value[0].url}}?cache={{vm.form[form_config.type].cache_timestamp}}"><img class="thumb-xl" ng-show="field.value[0].url" ng-src="{{field.value[0].url}}?cache={{vm.form[form_config.type].cache_timestamp}}"/></a>
                                            <div ng-if="field.name == 'profile_img'">
                                                <button type="button" ng-click="deleteProfileImage()"
                                                        confirm="Delete Profile Image ?"
                                                        confirm-settings="{size: 'sm', windowTopClass: 'modal-confirm'}"
                                                        class="btn btn-xs btn-danger"><i class="fa fa-fw fa-trash-o"></i> Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div ng-switch-default>
                                        <div class="wrapper-sm">
                                            <a target="_blank" href="{{field.value[0].url}}"><span class="m-r-xs"><i class="fa fa-file-o"></i></span>{{field.value[0].file_name}}</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div>
                            <div ng-switch="field.params.type">
                                <div ng-switch-when="multiple">
                                    <input type="file"
                                           nv-file-select
                                           class="custom-file-input-multi"
                                           uploader="uploader"
                                           multiple/>
                                </div>
                                <div ng-switch-when="single" ng-hide="uploader.queue.length == 1">
                                    <input type="file"
                                           class="custom-file-input-single"
                                           nv-file-select
                                           uploader="uploader"
                                    />
                                </div>
                            </div>
                            <span ng-show="uploader.queue.length"><b class="badge bg-info">{{ uploader.queue.length }}</b> files selected</span>
                        </div>

                        <div class="padder-v padder-v-notop">
                            <table class="table table-condensed table-hover" ng-show="uploader.queue.length">
                                <thead>
                                <tr>
                                    <th width="50%">Name</th>
                                    <th ng-show="uploader.isHTML5">Size</th>
                                    <th ng-show="uploader.isHTML5">Progress</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr ng-repeat="item in uploader.queue">
                                    <td>
                                        <strong>{{ item.file.name }}</strong>
                                    </td>
                                    <td ng-show="uploader.isHTML5" nowrap>{{ item.file.size/1024/1024|number:2 }} MB</td>
                                    <td ng-show="uploader.isHTML5">
                                        <div class="progress progress-sm m-b-none m-t-xs">
                                            <div class="progress-bar bg-info" role="progressbar" ng-style="{ 'width': item.progress + '%' }"></div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span ng-show="!item.isSuccess && !item.isCancel && !item.isError" class="text-info"><i class="glyphicon glyphicon-upload"></i></span>
                                        <span ng-show="item.isSuccess" class="text-success"><i class="glyphicon glyphicon-ok"></i></span>
                                        <span ng-show="item.isCancel" class="text-warning"><i class="glyphicon glyphicon-ban-circle"></i></span>
                                        <span ng-show="item.isError" class="text-danger"><i class="glyphicon glyphicon-remove"></i></span>
                                    </td>
                                    <td nowrap>
                                        <!--<button type="button" class="btn btn-default btn-xs" ng-click="item.upload()" ng-disabled="item.isReady || item.isUploading || item.isSuccess">
                                            Upload
                                        </button>-->
                                        <button type="button" class="btn btn-default btn-xs" ng-click="item.cancel()" ng-disabled="!item.isUploading">
                                            Cancel
                                        </button>
                                        <button type="button" class="btn btn-default btn-xs" ng-click="item.remove()">
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <!-- Only show crop on single image upload -->
                            <div class="hbox hbox-auto-xs" ng-if="field.params.type == 'single' && uploader.queue.length && field.params.resize_width && field.params.resize_height">
                                <div class="col">
                                    <div class="wrapper-sm b-a bg-white m-r-xs m-b-xs">
                                        <div class="bg-light crop-area">
                                            <!-- crop area if uploaded image-->

                                            <img-crop
                                                    area-type="square"
                                                    image="crop_source.image"
                                                    result-image="cropped.image"
                                                    result-image-size="'{{field.params.resize_width}}'"
                                            ></img-crop>

                                            <!--<ui-cropper
                                                        area-type="square"
                                                        image="'https://raw.githubusercontent.com/CrackerakiUA/ui-cropper/master/screenshots/live.jpg'"
                                                        xximage="crop_source.image"
                                                        url-blob="cropped.url_blob"
                                                        result-image="cropped.image"
                                                        result-image-size="'{{field.params.resize_width}}'"
                                                        result-image-quality="'max'"

                                            ></ui-cropper>--->

                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="inline bg-white wrapper-sm b-a">
                                        <div class="bg-light">
                                            <img ng-src="{{cropped.image}}" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="m-b">
                                <div ng-show="uploader.isUploading">
                                    <p>Upload progress:</p>
                                    <div class="progress bg-light dker" style="">
                                        <div class="progress-bar progress-bar-striped bg-info" role="progressbar" ng-style="{ 'width': uploader.progress + '%' }"></div>
                                    </div>
                                </div>
                                <!--
                                Removed due to auto upload all on submit
                                <button type="button" class="btn btn-addon btn-success" ng-click="uploader.uploadAll()" ng-disabled="!uploader.getNotUploadedItems().length">
                                    <i class="fa fa-arrow-circle-o-up"></i> Upload all
                                </button>-->
                                <button type="button" class="btn btn-xs btn-warning" ng-click="uploader.cancelAll()" ng-show="uploader.isUploading">
                                    <i class="fa fa-ban"></i> Cancel all
                                </button>
                                <button type="button" class="btn btn-xs btn-danger" ng-click="uploader.clearQueue()" ng-show="uploader.queue.length">
                                    <i class="fa fa-trash-o"></i> Remove all
                                </button>
                            </div>


                        </div>
                    </div>

                </div>
            </div>
        </div>

    </script>

    <script type="text/ng-template" id="field.rich_text">
        <div ng-switch="field.params.readonly" ng-if="vm.form[form_config.type].field_show[field.name]">
            <div ng-switch-when="true">
                <div class="form-group">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <div>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                        </div>
                    </label>

                    <div class="col-xs-6 col-sm-8">
                        <span ng-hide="field.params.readonly_msg">{{field.value}}</span>
                        <span ng-show="field.params.readonly_msg"><em>{{field.params.readonly_msg}}</em></span>
                    </div>
                </div>
            </div>
            <div ng-switch-default>
                <div id="{{field.name}}" class="form-group" ng-class="{ 'has-error' : vm.appform[form_config.type][field.name].$invalid && !vm.appform[form_config.type][field.name].$pristine }">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <p>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                            <span ng-show="field.rules.required" class="m-l-xs"><code>*</code></span>
                        </p>
                        <em><span class="text-muted" ng-bind-html="field.description | unsafe"></span></em>
                    </label>

                    <div class="col-xs-6 col-sm-8">

                        <textarea name="{{field.name}}"
                                  ng-model-options="{debounce: 500}"
                                  ng-required="field.rules.required"
                                  style="height: 400px;"
                                  ui-tinymce="vm.form[form_config.type].field_settings[field.name].tinymce_options"
                                  ng-model="vm.form[form_config.type].field_model[field.name]"
                                  ng-init="vm.form[form_config.type].field_model[field.name] = field.value"></textarea>

                        <div class="m-t-xs">
                            <ui-select ng-model="vm.email_template" theme="bootstrap" on-select="vm.updateEmailTemplate($item, $model, field.name)">
                                <ui-select-match placeholder="Select Email Template ...">{{$select.selected.name}}</ui-select-match>
                                <ui-select-choices
                                        group-by="'campaign_type'"
                                        repeat="item in vm.email_templates | filter: $select.search"
                                        all-choices="vm.email_templates"
                                        refresh-delay="0"
                                >
                                    <div ng-bind-html="item.name | highlight: $select.search"></div>
                                </ui-select-choices>
                            </ui-select>
                        </div>


                        <div ng-messages="vm.appform[form_config.type][field.name].$error">
                            <div class="pull-left" ng-repeat="rule in field.rules">
                                <div ng-if="rule.rule_type != 'required'" ng-message-exp="rule.rule_type" class="help-block"><i>{{rule.rule_msg}}</i></div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </script>

    <script type="text/ng-template" id="field.checkbox">
        <div ng-switch="field.params.readonly" ng-if="vm.form[form_config.type].field_show[field.name]">
            <div ng-switch-when="true">
                <div class="form-group">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <div>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                        </div>
                    </label>

                    <div class="col-xs-6 col-sm-8">
                        <div ng-hide="field.params.readonly_msg" ng-repeat="option in field.options">
                            <div>{{vm.form[form_config.type].field_model[field.name][option.option_value]}}</div>
                        </div>

                        <span ng-show="field.params.readonly_msg"><em>{{field.params.readonly_msg}}</em></span>
                    </div>
                </div>
            </div>
            <div ng-switch-default>
                <div id="{{field.name}}" class="form-group" ng-class="{ 'has-error' : vm.appform[form_config.type][field.name].$invalid && !vm.appform[form_config.type][field.name].$pristine }">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <p>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                            <span ng-show="field.rules.required" class="m-l-xs"><code>*</code></span>
                        </p>
                        <em><span class="text-muted" ng-bind-html="field.description | unsafe"></span></em>
                    </label>

                    <div class="col-xs-6 col-sm-8">
                        <div class="checkbox" ng-repeat="option in field.options">
                            <label class="i-checks i-checks-sm">
                                <input type="checkbox"
                                       name="{{field.name}}"
                                       ng-model="vm.form[form_config.type].field_model[field.name][option.option_value]"
                                       ng-true-value="'{{option.option_value}}'"
                                       ng-required="!vm.someSelected(vm.form[form_config.type].field_model[field.name].options,field.rules.required)"
                                > <i></i>
                                {{option.option_text}}
                            </label>
                        </div>

                        <div ng-messages="vm.appform[form_config.type][field.name].$error">
                            <div class="pull-left" ng-repeat="rule in field.rules">
                                <div ng-message-exp="rule.rule_type" class="help-block"><i>{{rule.rule_msg}}</i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </script>

    <script type="text/ng-template" id="field.tags">
        <div ng-switch="field.params.readonly" ng-if="vm.form[form_config.type].field_show[field.name]">
            <div ng-switch-when="true">
                <div class="form-group">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <div>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                        </div>
                    </label>

                    <div class="col-xs-6 col-sm-8">
                        <div ng-hide="field.params.readonly_msg" ng-repeat="option in field.options">
                            <div>{{vm.form[form_config.type].field_model[field.name][option.option_value]}}</div>
                        </div>

                        <span ng-show="field.params.readonly_msg"><em>{{field.params.readonly_msg}}</em></span>
                    </div>
                </div>
            </div>
            <div ng-switch-default>
                <div id="{{field.name}}" class="form-group" ng-class="{ 'has-error' : vm.appform[form_config.type][field.name].$invalid && !vm.appform[form_config.type][field.name].$pristine }">
                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <p>
                            <span class="font-bold" ng-bind-html="field.title | unsafe"></span>
                            <span ng-show="field.rules.required" class="m-l-xs"><code>*</code></span>
                        </p>
                        <em><span class="text-muted" ng-bind-html="field.description | unsafe"></span></em>
                    </label>

                    <div class="col-xs-6 col-sm-8">

                        <tags-input ng-model="vm.form[form_config.type].field_model[field.name]"
                                    placeholder="Add email"
                                    min-tags="1"
                                    add-on-space="true"
                                    add-on-comma="true"
                                    required="field.rules.required"
                                    allowed-tags-pattern="^[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+.)+[A-Za-z]{2,}"
                                    name="{{field.name}}"
                        ></tags-input>

                        <div ng-messages="vm.appform[form_config.type][field.name].$error">
                            <div class="pull-left" ng-repeat="rule in field.rules">
                                <div ng-message-exp="rule.rule_type" class="help-block"><i>{{rule.rule_msg}}</i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </script>

    <script type="text/ng-template" id="field.hidden">
        <div  ng-if="vm.form[form_config.type].field_show[field.name]">
            <div id="{{field.name}}" style="display: none">
                <input type="text"
                       ng-init="vm.form[form_config.type].field_model[field.name] = field.value"
                       ng-model="vm.form[form_config.type].field_model[field.name]"
                       name="{{field.name}}"

                />
            </div>
        </div>
    </script>

    <script type="text/ng-template" id="field.sub_field">
        <div ng-switch="field.params.readonly" ng-if="vm.form[form_config.type].field_show[field.name]">
            <div ng-switch-when="true">

                <div class="form-group">

                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <div class="m-b-sm b-b m-t-sm">
                            <span class="h5" ng-bind-html="field.title | unsafe"></span>
                        </div>
                    </label>
                </div>
                <div ng-repeat="field in field.sub_fields">
                    <div ng-include="vm.getFieldTemplate(field.template)"></div>
                    <div class="line line-xs"></div>
                </div>

            </div>
            <div ng-switch-default>
                <div id="{{field.name}}" class="form-group" ng-class="{ 'has-error' : vm.appform[form_config.type][field.name].$invalid && !vm.appform[form_config.type][field.name].$pristine }">

                    <label class="col-xs-6  col-sm-4 control-label form-field-label">
                        <div class="b-b m-t-sm">
                            <span class="h5" ng-bind-html="field.title | unsafe"></span>
                            <span ng-show="field.rules.required" class="m-l-xs"><code>*</code></span>
                        </div>
                        <div class="m-b-sm">
                            <em><span class="text-muted" ng-bind-html="field.description | unsafe"></span></em>
                        </div>
                    </label>

                    <div class="col-xs-6 col-sm-8">

                    </div>
                </div>
                <div ng-repeat="field in field.sub_fields">
                    <div ng-include="vm.getFieldTemplate(field.template)"></div>
                    <div class="line line-xs"></div>
                </div>
            </div>
        </div>
    </script>

    <ng-form name="vm.appform[form_config.type]"
             class="form-horizontal form-validation"
             novalidate
             xxng-enter="vm.autoSubmitSearch()"
    >

        <div ng-show="vm.form[form_config.type].state.status" class="text-center m-b-sm">
            <div class="alert alert-success m-b-md w-full clear" ng-show="vm.form[form_config.type].state.message">{{vm.form[form_config.type].state.message}}</div>

            <button ng-hide="!vm.form[form_config.type].show_cancel" type="button" ng-click="cancel()" class="btn btn-default">
                Close
            </button>

            <button ng-show="!form_config.id && form_config.add_more && form_config.type != 'faculty'" type="button" ng-click="vm.reset_form(form_config.type)" class="btn btn-default">
                Add More
            </button>

            <!-- ADD MORE FACULTY -->
            <button ng-show="!form_config.id && form_config.add_more && form_config.type == 'faculty'" type="button" ng-click="vm.emitResetExternalSearch()" class="btn btn-default">
                Add More Faculty
            </button>

            <button ng-show="form_config.type == 'campaign_test'" type="button" ng-click="vm.reset_form(form_config.type)" class="btn btn-default">
                Send Again
            </button>

            <button ng-show="form_config.type == 'external_user_search'" type="button" ng-click="vm.reset_form(form_config.type); vm.emitResetExternalSearch()" class="btn btn-default">
                Search Again
            </button>

            <button ng-show="form_config.type == 'external_user_search'" type="button" ng-click="vm.emitAddAsNew()" class="btn btn-default">
                Add as New
            </button>

            <button ng-show="form_config.type == 'conferences' && !form_config.id && !form_config.conference_id" type="button" ng-click="vm.emitShowConferenceFaculty()" class="btn btn-default m-l-sm">
                Add Meeting Faculty
            </button>

            <button ng-show="form_config.type == 'conferences' && !form_config.id && !form_config.conference_id" type="button" ng-click="vm.emitShowEvent()" class="btn btn-default m-l-sm">
                Add Events
            </button>

            <button ng-show="form_config.type == 'faculty' && form_config.id" type="button" ng-click="vm.reset_form(form_config.type);" class="btn btn-default m-l-sm">
                Show Faculty Profile
            </button>

            <!--
                        <button ng-show="form_config.type == 'events' && !form_config.id && !form_config.conference_id" type="button" ng-click="vm.emitShowFaculty()" class="btn btn-default m-l-sm">
                            Add Faculty
                        </button>

                        <button ng-show="returned_node.depth == 1 && form_config.type == 'events' && !form_config.id && !form_config.conference_id" type="button" ng-click="vm.emitAddChildNode()" class="btn btn-default m-l-sm">
                            Add Presentation
                        </button>

                        <button ng-show="returned_node.depth == 1 && form_config.type == 'events' && !form_config.id && !form_config.conference_id" type="button" ng-click="vm.emitAddAnotherSectionNode()" class="btn btn-default m-l-sm">
                            Add Another Section
                        </button>
            --->
            <button ng-show="returned_node.depth == 0 && form_config.type == 'events' && form_config.conference_id" type="button" ng-click="vm.emitAddSectionNode()" class="btn btn-default m-l-sm">
                Add Section
            </button>

            <!--<pre>{{form_config}}</pre>
            <pre>{{returned_node | json}}</pre>--->
        </div>
        <!--<pre>{{form_config | json}}</pre>
        <pre>{{vm.form | json}}</pre>
        {{vm.form[form_config.type].state.status}}--->
        <div ng-hide="vm.form[form_config.type].state.status">

            <uib-alert ng-repeat="alert in alerts[form_config.type]" type="{{alert.type}}" close="closeAlert(form_config.type, $index)">{{alert.msg}}</uib-alert>

            <div ng-hide="form_config.hide_required_fields_text">
                <div ng-show="vm.form[form_config.type].show_submit && vm.form[form_config.type].data.fieldsets.length > 0 && form_config.mode != 'readonly'" class="padder-v-bottom">
                    <div ng-show="form_config.form_description" class="b-b m-b-xs font-bold">{{form_config.form_description}}</div>
                    <em>All fields marked with </em> <span class="m-l-xs"><code>*</code></span> <em>are required.</em>
                </div>

                <div ng-show="vm.form[form_config.type].show_submit && !vm.form[form_config.type].data.fieldsets.length && form_config.mode != 'readonly'" class="form-group">
                    <div ng-class="{'col-xs-6 col-sm-8 col-sm-offset-4' : vm.form[form_config.type].data.sub_type == 'standard', 'm-l' : vm.form[form_config.type].data.sub_type == 'search'}">
                        <div ng-show="form_config.form_description" class="b-b m-b-xs font-bold">{{form_config.form_description}}</div>
                        <em>All fields marked with</em> <span class="m-l-xs"><code>*</code></span> <em>are required.</em>
                    </div>
                </div>
            </div>

            <!-- Non-Search Fieldsets -->
            <div ng-if="vm.form[form_config.type].data.fieldsets.length && vm.form[form_config.type].data.sub_type != 'search'">
                <uib-accordion close-others="false">
                    <div uib-accordion-group class="panel-default" ng-init="form_accordion[$index].open = true" is-open="form_accordion[$index].open" ng-if="vm.form[form_config.type].data.fieldsets.length > 0" ng-repeat="fieldset in vm.form[form_config.type].data.fieldsets">
                        <uib-accordion-heading>
                            {{fieldset.title}}
                            <i class="pull-right glyphicon" ng-class="form_accordion[$index].open ? 'glyphicon-chevron-down':'glyphicon-chevron-right'"></i>
                        </uib-accordion-heading>

                        <div ng-repeat="field in fieldset.fields">
                            <div ng-include="vm.getFieldTemplate(field.template)"></div>
                            <div class="line line-xs"></div>
                        </div>

                    </div>
                </uib-accordion>
            </div>

            <!-- Non Collapsible Fieldsets-->
            <!--<div ng-if="vm.form[form_config.type].data.fieldsets.length > 0" ng-repeat="fieldset in vm.form[form_config.type].data.fieldsets">
                <div class="panel panel-default">
                    <div class="panel-heading font-bold">
                        {{fieldset.title}}
                    </div>
                    <div class="panel-body">
                        <div ng-repeat="field in fieldset.fields">
                            <div ng-include="vm.getFieldTemplate(field.template)"></div>
                            <div class="line line-dashed b-b" ng-show="!$last"></div>
                        </div>
                    </div>
                </div>
            </div>--->

            <!-- Non-Fieldset fields -->
            <div ng-if="!vm.form[form_config.type].data.fieldsets.length && vm.form[form_config.type].data.sub_type == 'standard'">
                <div class="line line-dashed b-b"></div>
                <div ng-repeat="field in vm.form[form_config.type].data.fields">
                    <div ng-include="vm.getFieldTemplate(field.template)"></div>
                    <div class="line line-xs" ng-show="!$last"></div>
                </div>
                <div class="line line-dashed b-b"></div>
            </div>


            <!-- Column fields -->
            <div ng-if="vm.form[form_config.type].data.sub_type == 'search'" class="form-column">
                <div class="line line-dashed b-b"></div>

                <!-- Search Non-Fieldset --->
                <div ng-if="!vm.form[form_config.type].data.fieldsets.length" ng-repeat="field_row in vm.form[form_config.type].data.field_rows">
                    <div class="row">
                        <div class="col-sm-6" ng-repeat="field in field_row">
                            <div ng-include="vm.getFieldTemplate(field.template)"></div>
                        </div>
                    </div>
                    <div class="line line-xs" ng-show="!$last"></div>
                </div>

                <!-- Search Fieldset --->
                <div ng-if="vm.form[form_config.type].data.fieldsets.length">
                    <uib-accordion close-others="false">
                        <div ng-repeat="fieldset_row in vm.form[form_config.type].data.fieldset_rows" class="m-b-sm">
                            <div class="row">
                                <div class="col-sm-6" ng-repeat="fieldset in fieldset_row">

                                    <div uib-accordion-group class="panel-default" ng-init="form_accordion[$index].open = true" is-open="form_accordion[$index].open">
                                        <uib-accordion-heading>
                                            {{fieldset.title}}
                                            <i class="pull-right glyphicon" ng-class="form_accordion[$index].open ? 'glyphicon-chevron-down':'glyphicon-chevron-right'"></i>
                                        </uib-accordion-heading>

                                        <div ng-repeat="field in fieldset.fields">
                                            <div ng-include="vm.getFieldTemplate(field.template)"></div>
                                            <div class="line line-xs"></div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </uib-accordion>

                    <div class="line line-xs" ng-show="!$last"></div>
                </div>

                <div class="line line-dashed b-b"></div>
            </div>

            <!--<pre>{{vm.appform[form_config.type].$error | json}}</pre>
            <pre>{{vm.appform[form_config.type].$invalid | json}}</pre>-->

            <!-- Error message after submit -->
            <div class="form-group m-b-sm" ng-show="vm.appform[form_config.type].$valid && form_submit_error">
                <div ng-class="{'col-xs-6 col-sm-8 col-sm-offset-4' : vm.form[form_config.type].data.sub_type == 'standard', 'm-l' : vm.form[form_config.type].data.sub_type == 'search'}">
                    <div class="text-danger font-bold">
                        {{form_submit_error}}
                    </div>
                </div>
            </div>

            <!-- Errors before submit -->
            <div class="form-group m-b-sm" ng-show="vm.appform[form_config.type].$invalid && form_config.mode != 'readonly'">
                <div ng-class="{'col-xs-6 col-sm-8 col-sm-offset-4' : vm.form[form_config.type].data.sub_type == 'standard', 'm-l' : vm.form[form_config.type].data.sub_type == 'search'}">

                    <div ng-repeat="(key, errors) in vm.appform[form_config.type].$error track by $index">

                        <div class="text-danger font-bold" ng-if="key == 'required'">
                            <em>Please fill out the following required fields:</em>
                            <div class="line line-dashed b-b-danger"></div>
                            <div ng-repeat="e in errors | unique:'$name'">
                                <span class="font-bold">
                                    {{ vm.getFieldName(e.$name) }}
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-group m-b-none" ng-show="vm.form[form_config.type].show_submit">
                <div ng-class="{'col-xs-6 col-sm-8 col-sm-offset-4' : vm.form[form_config.type].data.sub_type == 'standard', 'm-l' : vm.form[form_config.type].data.sub_type == 'search'}">
                    <div>
                        <button type="button"
                                ng-click="vm.submit()"
                                class="btn btn-success btn-sm m-r-sm"
                                ng-disabled="vm.appform[form_config.type].$invalid || $root.disable_submit">{{vm.form[form_config.type].submit_text}}
                        </button>
                        <button ng-if="vm.form[form_config.type].data.sub_type == 'standard'"
                                ng-hide="!vm.form[form_config.type].show_cancel"
                                type="button"
                                ng-click="cancel()"
                                ng-disabled="$root.disable_submit"
                                class="btn btn-danger btn-sm m-r-sm">Cancel
                        </button>
                        <button ng-if="vm.form[form_config.type].data.sub_type == 'search'"
                                ng-hide="!vm.form[form_config.type].show_cancel"
                                type="button"
                                ng-click="vm.resetSearchForm(form_config.type, true)"
                                ng-disabled="$root.disable_submit"
                                class="btn btn-danger btn-sm">Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-form>

    <div ng-hide="vm.loadedForm" class="app_loader_mini_container"><div class="app_loader_mini"></div></div>

</div>